.p-datepicker table td > span.p-highlight {
  background-color: #1C7F9D;
  color: white;
}

.p-datepicker table td.p-datepicker-today > span {
  background-color: #18A3CB;
  color: white;
}

.p-datepicker-title * {
  margin-left: .2rem;
  margin-right: .2rem;
}

.dateselect .p-inputtext {
  border: 0;
  text-align: center;
}

.dateselect .p-inputtext:enabled:hover {
  box-shadow: none;
  background-color: $gray;
}

.dateselect .p-inputtext:enabled:focus {
  box-shadow: none;
}

.p-datepicker-trigger {
  border-radius: 0 .5rem .5rem 0;
}


.mv-calendar .p-inputtext.p-dropdown-label {
  padding: .5rem .75rem;
  line-height: 1.25rem;
  font-size: .85rem;
  border: 0;
  margin-right: -1px;
  min-width: 8rem;
}

.mv-calendar .p-inputtext {
  padding: .25rem 1rem;
  font-weight: 500;
  font-size: .9rem;
  line-height: .9rem;
  font-family: var(--font);
  border-radius: var(--rounded);
  border: 1px solid var(--gray);
  transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
  box-sizing: border-box;
  outline: none;
  color: var(--black);
  width: 100%;
  height: 2.3rem;
}

.mv-calendar .p-inputtext:focus {
  box-shadow: var(--primary-lighter) 0px 0px 5px;
  border: 1px solid var(--primary-light);
}

/** New Styling **/

// .p-inputtext {
//   padding: .45rem 1rem;
//   font-size: .85rem;
//   font-weight: 500;
//   box-shadow: 0 0 transparent;
//   outline: none;
//   text-align: center;
  
// }

.p-calendar .p-inputtext {
//   box-shadow: 0 0 transparent;
//   outline: none;
//   color: var(--black-lighter);
//   padding-right: 0;
//   padding-left: 0;
  cursor: pointer;
//   border-radius: var(--rounded);
}

.p-calendar .p-inputtext:hover {
  background-color: var(--gray-lightest);
}

.p-datepicker {
  border-radius: .7rem;
}

.p-datepicker table td {
  padding: .35rem;
}

.p-datepicker-title button {
  margin: 0 .25rem;
}

.p-datepicker-calendar tbody tr td .p-element {
  padding: 1.25rem;
}

.p-datepicker-calendar tbody tr td .p-element.p-highlight {
  background-color: var(--secondary-lightest);
  color: var(--black);
}

.p-datepicker-calendar tbody tr td.p-datepicker-today .p-element {
  background-color: var(--primary-lighter);
}

.p-datepicker-calendar tbody tr td > span {
  padding: .5rem .75rem;
  border-radius: 1rem;
  width: 1.25rem;
}

.p-datepicker:not(.p-datepicker-inline) {
  box-shadow: var(--gray-light) 0px 10px 25px;
}

.p-datepicker table td.p-datepicker-today {
  width: 1.25rem;
}

.p-datepicker table td.p-datepicker-today > span {
  color: var(--black)
}