.mv-table-empty {
    text-align: center;
    padding: 4rem;
}

.mv-table-empty .mv-table-empty__icon {
    font-size: 2.65rem;
    color: var(--primary);
    margin-top: .5rem;
}

.mv-table-empty .mv-table-empty__label {
    font-size: 1.65rem;
    font-weight: bold;
    color: var(--gray);
    margin: .5rem 0;
}