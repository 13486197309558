/* .p-datatable-tbody tr td.mv-table-empty {
    background-color: white;
    text-align: center;
    padding: 4rem;
  }
  
  .p-datatable-tbody tr td.mv-table-empty .mv-table-empty__icon {
    font-size: 2.65rem;
    color: var(--primary);
  }
  
  .p-datatable-tbody tr td.mv-table-empty .mv-table-empty__label {
    font-size: 1.65rem;
    font-weight: bold;
    color: var(--gray);
    margin-top: .25rem;
  }
  
  .p-datatable-tbody tr td.mv-table-empty:hover {
    background-color: white;
    cursor: default;
  } */


.mv-empty {
    display: flex;
    flex-direction: column;
    background-color: white;
    text-align: center;
    padding: 4rem;
    width: 100%;
}

.mv-empty__icon {
    font-size: 2.65rem;
    color: var(--primary);
}

.mv-empty__label {
    font-size: 1.65rem;
    font-weight: bold;
    color: var(--gray);
    margin-top: .25rem;
}