.p-toast .p-toast-message {
    border-radius: .7rem;
    box-shadow: 0;
    font-size: .75rem;
}

.p-toast .p-toast-message.p-toast-message-error {
    background: var(--red-lightest);
    border: 1px solid var(--red);
}

.p-toast .p-toast-message.p-toast-message-info {
    background: var(--primary-lightest);
    border: 1px solid var(--primary-light);
    color: var(--primary);
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon {
    color: var(--primary);
}


.p-toast-summary {
    font-size: .75rem;
    margin-bottom: .25rem;
}

.p-toast-summary,
.p-toast-detail {
    margin: 0;
    line-height: 1rem;
}

.p-toast-message-icon {
    display: flex;
    align-content: center;
    align-items: center;
    align-self: center;
}

.p-toast-message-icon .p-icon-wrapper {
    padding: 0;
}

.p-toast-message-icon .p-icon-wrapper > svg {
    height: 1.25rem;
}