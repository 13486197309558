.p-inputtext {
  padding: .5rem .75rem;
  font-size: .9rem;
  line-height: 1.25rem;
  border: 1px solid $gray;
  border-radius: var(--rounded);
}

.p-dropdown-trigger {
  border-left: 1px solid $gray;
}

.p-input-icon-left .p-inputtext {
  padding-left: 2.25rem;
}

.p-inputgroup-addon {
  padding: .5rem;
  border-radius: var(--rounded) 0rem 0rem var(--rounded);
}

.p-inputgroup .p-inputtext {
  border-radius: 0rem var(--rounded) var(--rounded) 0rem;
}

// Outline
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--primary-lighter) 0px 0px 5px;
  border-color: var(--primary-light);
}

.p-calendar.p-calendar-w-btn .p-inputtext {
  border-radius: var(--rounded) 0rem 0rem var(--rounded);
}

.p-calendar .p-datepicker-trigger {
  border-radius: 0rem var(--rounded) var(--rounded) 0rem;
}