.p-dialog {
  box-shadow: var(--black) 0px 10px 25px;
  border-radius: 1rem;
  min-width: 48rem;
}

.p-dialog-header {
  padding: 1.5rem;
  color: $secondary;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  // border-radius: .5rem .5rem 0 0; Old one
  border-radius: var(--rounded-lg) var(--rounded-lg) 0 0;
}

.p-dialog-header-icon {
  color: var(--black-lighter);
  background-color: var(--gray-light);
  padding: .5rem 1.25rem;
  border-radius: .85rem;
  font-size: .5rem;

  &:hover {
    background-color: var(--secondary-light)
  }

  &:active {
    background-color: $gray-dark;
  }
}

.p-dialog-title {
  font-size: 1.125rem;
  color: var(--secondary);
  font-weight: 700;
}

.p-dialog-content {
  overflow-x: visible;
  overflow-y: visible;
  padding: 1.5rem;
  // border-radius:0 0 .5rem .5rem; Old one
  
  border-radius:0 0 var(--rounded-lg) var(--rounded-lg);
}

.gn-modal-content {
  padding: 1.25rem;
  font-weight: 400;
  font-size: 1rem;
}

.gn-modal-footer > div {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $gray;
  padding: 1.25rem;
}

// Necessary because of no animations module active (NoopAnimationModule)
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}