.p-button {
  padding: .65rem 1.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  color: $white;
  background-color: $primary;
  border-radius: 1rem;
}

.p-button:hover {
  background-color: $primary-light;
  transition: all;
}

.p-button:focus {
  box-shadow: 0 0 0 0.2rem $primary-pale;
}

.p-button.p-button-text {
  color: $secondary;
  background-color: transparent;
}

.p-button.p-button-text:hover {
  color: $primary-light;
  background-color: $gray-light;
  transition: all;
}

.p-splitbutton-defaultbutton {
  border-radius: .5rem 0 0 .5rem;
}

.p-splitbutton-menubutton {
  border-radius: 0 .5rem .5rem 0;
}

.p-togglebutton {
  border: 1px solid $gray;
  background-color: white;
  color: $gray;

  &:hover {
    border: 1px solid transparent;
    .p-button-icon {
      color: white;
    }
  }
}

.p-togglebutton.p-highlight {
  border: 1px solid $primary;
  background-color: $primary;
  color: white;
}

.p-togglebutton.p-highlight .p-button-icon{
  color: white;
}

.p-togglebutton .p-button-icon {
  color: $black;
}
