.ga-highlight {
    padding: .25rem .5rem;
    border-radius: var(--rounded);
 }
 
 .ga-highlight-red {
     background-color: rgba(255, 0,0, 40%);
     color: black;
 }
 
 .ga-highlight-orange {
     background-color: rgba(255, 175, 75, 50%);
     color: black;
 }