.p-menu {
    box-shadow: var(--gray-light) 0px 10px 25px;
    border-radius: var(--rounded);
    font-family: var(--font);
    font-size: .85rem;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 0;
    padding: 0;
}

.p-submenu-header {
    padding: .65rem 1rem;
}

.p-menuitem-content {
    margin: 0;
    padding: 0;
}

.p-menuitem-link {
    line-height: .9rem;
    padding: .65rem 1rem;
}

.p-menuitem-link:hover {
    background-color: var(--gray-light);
    color: var(--primary-darker);
}

.p-menuitem-link .p-menuitem-text {
    font-size: .85rem;
}