.p-inputswitch.p-inputswitch-checked:not(.p-disabled) .p-inputswitch-slider {
    background-color: var(--primary);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background-color: var(--primary-light);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):active .p-inputswitch-slider {
    outline: none;
}

.yellow .p-inputswitch.p-inputswitch-checked:not(.p-disabled) .p-inputswitch-slider {
    background-color: var(--yellow);
}

.red .p-inputswitch .p-inputswitch-slider {
    background-color: var(--red);
}