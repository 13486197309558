:root {
    --maskbg: #000000CC;

    --transparent: transparent;
    --black-transparent: #000000CC;
    --black: #333;
    --black-darker: #282828;
    --black-dark: #161616;
    --black-lighter: #666666;
    --white: #fff;
    --whitish: #F2F4F4;

    --primary: #1C7F9D;
    --primary-light: #18A3CB;
    --primary-lighter: #7dcffa;
    --primary-lightest: #6cbfe9;
    --primary-pale: #d7f2ff;
    --primary-medium: #386979;

    --secondary: #486169;
    --secondary-alt: #546A71;
    --secondary-lighter: #7B959D;
    --secondary-light: #A7B8BE;
    --secondary-lightest: #E2EEF2;

    --gray-dark: #adb8c1;
    --gray: #BECBD0;
    --gray-light: #E2EEF2;
    --gray-light-alt: #E4ECEF;
    --gray-lightest: #EDF5F7;

    --red: #FF4B4B;
    --red-lighter: #FF9999;
    --red-light: #FFCBCB;
    --red-dark: #E50000;

    --yellow-lighter: #ffdd00;
    --yellow: #FFE667;
    --yellow-dark: #7C6C17;

    --orange-light: #ffba61;
    --orange-dark: #785105;

    --green: #71EE52;
    --green-dark: #335A2A;
    --green-light: #ACFF97;

    --screen-sm: '320px';
    --screen-md: '768px';
    --screen-lg: '1250px';

    --radius-md: .5rem;
    --rounded-lg: 1rem;

    --page-bg: #F2F4F4;
    --transparent: transparent;

    --black-transparent: #000000CC;
    --black: #333333;
    --black-darker: #282828;
    --black-dark: #161616;
    --black-lighter: #666666;
    --white: #fff;
    --whitish: #F2F4F4;

    --primary: #1c7f9d;
    --primary-light: #18A3CB;
    --primary-darker: #0d566e;
    --primary-dark: #033647;
    --primary-darkest: #021f29;
    --primary-lighter: #B7E7F5;
    --primary-lightest: #f1f8fc;

    --secondary: #486169;
    --secondary-alt: #546A71;
    --secondary-lighter: #7B959D;
    --secondary-light: #A7B8BE;
    --secondary-lightest: #E2EEF2;

    --gray: #BECBD0;
    --gray-light: #E2EEF2;
    --gray-light-alt: #E4ECEF;
    --gray-lightest: #EDF5F7;

    --red: #FF4B4B;
    --red-lighter: #FF9999;
    --red-light: #FFCBCB;
    --red-lightest: rgb(255, 235, 235);
    --red-dark: #E50000;
    --red-darkest: #a10505;

    --s4: 1rem;
    --rounded: .7rem;
    --rounded-lg: 1rem;

    --main: 'Inter';
    --bold: 700;
    --medium: 600;

    --font: 'Inter'
}

// Global
@import 'assets/variables';

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;

// Form
@import 'assets/empty.css';
@import 'assets/panel';
@import 'assets/badge';
@import 'assets/button';
@import 'assets/input';
@import 'assets/dropdown';
@import 'assets/calendar';
@import 'assets/modal';
@import 'assets/table';
@import 'assets/checkbox';
@import 'assets/switch';
@import 'assets/tooltip';
@import 'assets/menu';
@import 'assets/highlight';
@import 'assets/empty';
@import 'assets/progressbar';
@import 'assets/toast';

.stat-green {
    color: #409D29;
}

.stat-green-light {
    color: #8FF077;
}

.stat-bg-green {
    background-color: #8FF077;
}

.stat-blue {
    color: #239CE0;
}

.stat-blue-light {
    color: #B1ECFF;
}

.stat-bg-blue {
    background-color: #B1ECFF;
}

.stat-red {
    color: #CE0F0F;
}

.stat-red-light {
    color: #FF9797;
}

.stat-bg-red {
    background-color: #FF9797;
}

.stat-yellow {
    color: #B49F37;
}

.stat-yellow-light {
    color: #FEE76F;
}

.stat-bg-yellow {
    background-color: #FEE76F;
}

body {
    background-color: #F3F3F3;
    font-family: 'Inter';
}

html {
    font-size: 16px;
}

.p-element {
    font-family: 'Inter';
}

.font-mono {
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 500;
    text-align: right;
    color: var(--secondary);
}

.icon-fill {
    font-variation-settings: 'FILL' 1;
}