.mv-input {
    padding: .25rem 1rem;
    font-weight: 500;
    font-size: .9rem;
    line-height: .9rem;
    font-family: var(--font);
    border-radius: var(--rounded);
    border: 1px solid var(--gray);
    transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
    box-sizing: border-box;
    outline: none;
    color: var(--black);
    width: 100%;
    height: 2.3rem;
}

.mv-input.ng-invalid.ng-untouched {
    border-color: var(--red)
}

.mv-input:focus {
    box-shadow: var(--primary-lighter) 0px 0px 5px;
    border: 1px solid var(--primary-light);
}

.mv-input-bare {
    padding: .25rem 1rem;
    font-weight: 500;
    font-size: .85rem;
    line-height: .85rem;
    font-family: var(--font);
    border-radius: 0rem;
    transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
    box-sizing: border-box;
    outline: none;
    color: var(--black);
    width: 100%;
    height: 2.2rem;
}

.mv-input-button {
    padding: .32rem .5rem;
    color: var(--black-lighter);
}