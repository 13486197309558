.p-dropdown {
  padding: 0;
  border-radius: var(--rounded);
}

.p-inputwrapper.p-dropdown {
  border: 1px solid $gray;
}

.dropdownwrapper:focus {
  box-shadow: 0 0 0 .5rem $primary-pale;
}

.p-inputtext.p-dropdown-label {
  padding: .5rem .75rem;
  line-height: 1.25rem;
  font-size: .9rem;
  border: 0;
  // border-radius: 0;
  margin-right: -1px;
  min-width: 8rem;
}

.p-dropdown-clear-icon {
  margin-right: 1rem;
  margin-left: 3rem;
  display: block;
}

.p-icon-wrapper {
  padding: 0 2rem;
}

.p-dropdown-filter-container .p-icon-wrapper {
  padding: 0;
}

.p-dropdown-item,
.p-menuitem {
  font-size: .9rem;
  font-weight: 500;
}

.p-dropdown-item.p-highlight {
  background-color: $secondary-lightest;
  color: $primary;
}

/** New Styling */

.p-inputtext {
  padding: .45rem 1rem;
  font-size: .85rem;
  font-weight: 500;
  box-shadow: 0 0 transparent;
  outline: none;
}

.p-dropdown-panel {
  box-shadow: var(--gray-light) 0px 10px 25px;
}

.p-dropdown {
  border: 1px solid var(--gray);
  border-radius: .7rem;
  box-shadow: 0 0 transparent;
  outline: none;
}

.p-dropdown:hover {
  background-color: var(--gray-lightest);
}

.p-dropdown .p-dropdown-label {
  padding-right: 0rem;
  text-align: left;
}

.p-dropdown-filter-container {
  min-width: 12rem;
}

.p-dropdown-header {
  margin-bottom: .25rem;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-icon-wrapper {
  width: 2rem;
}

.p-dropdown-panel {
  border-radius: var(--rounded);
  overflow: hidden;
}

.p-dropdown-item, .p-menuitem-content {
  padding: .45rem 1rem;
  font-size: .85rem;
  border-radius: 0;
}

.p-dropdown-item {
  margin: 0;
}

.p-dropdown-item:hover {
  background-color: var(--gray-light);
}

.p-dropdown-item.p-highlight {
  background-color: var(--primary-lighter);
  color: var(--primary-darker);
  font-weight: 600;
}

.ng-dirty.ng-invalid .p-dropdown {
  border: 1px solid var(--red);
}

.ng-dirty.ng-valid .p-dropdown {
  border: 1px solid var(--gray);
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.25rem;
}