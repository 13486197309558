.p-datatable-thead {
  background-color: $white;
}

.p-datatable-thead tr th {
  padding: .5rem .75rem;
  text-transform: uppercase;
  color: $primary;
  background-color: $white;
}

.p-datatable-thead tr th .p-icon-wrapper {
  float: right;
  padding: .2rem;
}

.p-datatable-thead tr th {
  font-size: .875rem;
  height: 2.8rem;
}

.p-datatable-tbody tr td {
  font-size: .9rem;
  padding: .75rem;
  line-height: 1.5rem;
}

.p-datatable-tbody tr td.font-mono {
  font-size: .925rem;
}

.p-datatable-tbody tr:nth-child(odd),
.p-datatable-tbody tr:nth-child(even) {
  cursor: pointer;
  &:hover {
    background-color: $gray-light;
  }
}

.p-datatable-tbody tr:nth-child(even) {
  background-color: $whitish;
}

.p-datatable-thead {
  border-bottom: 1px solid $gray-light;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
  background: var(--white);
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(odd) {
  background: var(--whitish);
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even):hover,
.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(odd):hover {
  background-color: var(--gray-light);
}

.p-datatable.p-datatable-small .p-datatable-tbody tr td {
  padding: .25rem .75rem;
}

.p-datatable-tbody tr td.mv-table-empty {
  background-color: white;
  text-align: center;
  padding: 4rem;
}

.p-datatable-tbody tr td.mv-table-empty .mv-table-empty__icon {
  font-size: 2.65rem;
  color: var(--primary);
}

.p-datatable-tbody tr td.mv-table-empty .mv-table-empty__label {
  font-size: 1.65rem;
  font-weight: bold;
  color: var(--gray);
  margin-top: .25rem;
}

.p-datatable-tbody tr td.mv-table-empty:hover {
  background-color: white;
  cursor: default;
}

.p-paginator-page {
  padding: .25rem;
  margin: .5rem;
  border-radius: .5rem;
  height: 1.75rem;
  min-width: 1.75rem;
  font-weight: 600;
}

.p-paginator-page:hover {
  background-color: var(--gray-light);
}

.p-paginator-page.p-highlight {
  background-color: var(--primary);
  color: var(--white);
}

.p-sortable-column .p-sortable-column-icon {
  color: var(--primary-lighter);
}

.p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--primary);
}

.p-sortable-column.p-highlight {
  background-color: var(--gray-light);
}